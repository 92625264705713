import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import useRedirectToLink from '../../hooks/useRedirectToLink'
import { useEffect, useState } from "react";
import { axiosInstance } from '../../services';
import useAsyncError from '../../hooks/useAsyncError';

const TopBanner = () => {
  const redirect = useRedirectToLink()
  const throwError = useAsyncError();

  const [special, setSpecial] = useState(null);

  useEffect(() => {
    const controller = new AbortController();

    const getSpecialData = async () => {
      try {
        const response = await axiosInstance.get("/get_special", { signal: controller.signal });
        const { special } = response?.data;
  
        if (special)
          setSpecial(special);        
      } catch (error) {
        throwError(new Error(error));
      }
    }

    getSpecialData();

    return () => controller.abort();
  }, [])

  if (!special) return <></>

  let styles = {}

  if (special.image)
    styles = { backgroundImage: `url(${special.image})` }

  return (
    <SlideDown>
      <div className="top-bar" style={styles}>
        <div href="categories-special.php" className="top-bar__inner" onClick={() => redirect(special.link)}>
          <p className="top-bar__title">{special.title}</p>
          <span className="top-bar__text">{special.description}</span>
          {special.link && <div className="button button--small button--white-secondary">Ver más</div>}
        </div>
        <div className="top-bar__shadow"></div>
        <i className="button button--icon button--icon-close ri-close-line" onClick={() => setSpecial(null)}></i>
      </div>
    </SlideDown>
  )
}

export default TopBanner;