// Funciones auxiliares que se usan en varios componentes
// Ejemplo para importar: import { formatDate } from '../../utils'

// Formatea fecha en formato para db: Ej 2022-12-31
export const formatDate = (date) => {
    if (date === null) return;
    try {
        // return date.toLocaleDateString('en-CA')
        return date.toISOString().split('T')[0];
    } catch (error) {
        console.log(error)
        return null
    }
}

// Devuelve la fecha de objecto js en formato americano. Ej: 2022-07-25
export const getFullDate = (date) => {
    try {
        return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
    } catch (error) {
        return ''
    }
}

export const secondsToTime = seconds => {
    const date = new Date(null);
    date.setSeconds(seconds);
    return date.toISOString().substr(11, 8);
}

// Formatea los puntos. Ej 10000 => '10.000'
export const formatPoints = (points) => {
    if (typeof points === 'string')
        points = points.replace('.', '');

    points = '' + points; // Lo paso a string
    return points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");;
}

// Si es uno le saco la 's' al final de la palabra. Ej: noche/noches, persona/personas
export const stringToSingular = (quantity, str) => {
    if (quantity === 1)
        return str.substring(0, str.length - 1)

    return str;
}

// Funcion range como en python o php
export const range = (start, stop, step) => {
    if (typeof stop == 'undefined') {
        // one param defined
        stop = start;
        start = 0;
    }

    if (typeof step == 'undefined') {
        step = 1;
    }

    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return [];
    }

    var result = [];
    for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
        result.push(i);
    }

    return result;
};

// Chequea que la variable sea un numero entero
export const isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}