import { useEffect, useState, useRef } from "react";
import useSetActive from "../../hooks/useSetActive";
import useOutsideHandler from "../../hooks/useOutsideHandler";
import useCloseMobileMenu from "../../hooks/useCloseMobileMenu";
import MenuMobile from "../UI/MenuMobile";
import { Link } from "react-router-dom";
import QuestionsModal from "./QuestionsModal";
import ConfirmationQuestionsModal from './ConfirmationQuestionsModal';
import HappierImg from '../../assets/images/logo-happier.png'
import TermsConditionsModal from "../UI/TermsConditionsModal";
import { axiosInstance } from "../../services";

const Footer = () => {
  const [activeClass, toggleActiveClass] = useSetActive();
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [fixedFooterClass, setFixedFooterClass] = useState("");
  const [footerHeight, setFooterHeight] = useState(0);

  const [showQuestionsModal, setShowQuestionsModal] = useState(false)
  const [showConfirmationCuestionModal, setConfirmationCuestionModal] = useState(false)
  const [showModal, setShowModal] = useState(false);

  const [responseQuestionData, setResponseQuestionData] = useState(false);

  const ref = useRef(null);

  const wrapperRef = useRef(null);
  useOutsideHandler(wrapperRef, toggleActiveClass, 'Footer');

  //Se deja de usar la funcion de mostrar segundo popup a pedido de Pablo.
  function showConfirmationMessage(questionData) {
    setShowQuestionsModal(false)

    if (questionData.id) {
      setResponseQuestionData(questionData);
      setConfirmationCuestionModal(true);
    }

  }

  useEffect(()=>{
    const checkForm = async () => {

      const response = await axiosInstance.get("/check_terms")
        .then(response => {
            if (response?.status === 200) {
                if(response.data.data.terms_agreed == 0){
                    setShowModal(true)
                }
            }
        })
        .catch(e => {
          console.log(e);
        })
    }
    checkForm()    
  },[])

  useEffect(() => {
    const fireOnScroll = () => {
      setShowBackToTop(window.pageYOffset > 500);

      if (window.pageYOffset + window.innerHeight <= document.body.clientHeight - footerHeight) {
        setFixedFooterClass("");
      } else {
        setFixedFooterClass("--fixed-footer");
      }
    };

    window.addEventListener("scroll", fireOnScroll, true);

    return () => {
      window.removeEventListener('scroll', fireOnScroll, true);
    }
  }, [footerHeight]);

  useEffect(() => {
    setFooterHeight(ref.current.clientHeight);
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div ref={ref}>
        {/* Footer */}
        <footer className="footer">
          <div className="footer__inner">
            <div className="section__holder">
              <div className="section__inner section__inner--grid-2">

                <div className="footer__item">
                  <div className="logo logo--small">
                    <Link to="/">
                      <img src={HappierImg} alt="Happier" />
                    </Link>
                  </div>
                  <p className="footer__text">Copyright 2024 - Todos los derechos reservados<br />Potenciado por <strong>Fanbag</strong></p>
                </div>

                <div className="footer__item">
                  <nav className="nav-menu">
                    <Link to="/terminos-condiciones" className="nav-menu__link nav-menu__link--footer">
                      Términos y condiciones
                    </Link>
                    <Link to="/preguntas-frecuentes" className="nav-menu__link nav-menu__link--footer">
                      Preguntas frecuentes
                    </Link>
                    <button type="button" onClick={() => setShowQuestionsModal(true)} className="button button--secondary js-btn-valoracion-experiencia">
                      Contacto
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <MenuMobile />

        {/* Ovelays */}
        <div className="overlay-menu js-menu-mobile-overlay" onClick={useCloseMobileMenu}></div>
        <div className={`overlay-menu overlay-menu--transparent js-desktop-menu-overlay ${activeClass}`}></div>
        <div className="select__overlay js-select-overlay"></div>

        {/* Whatsapp flotante */}
        <div className={`help-float ${fixedFooterClass}`} ref={wrapperRef}>
          <div className={`menu-desktop menu-desktop--help js-help-float ${activeClass}`}>
            <div className={`menu-desktop__content js-help-float-content ${activeClass}`}>
              <ul className="menu-desktop__nav">
                <li className="menu-desktop__nav__item">
                  <Link to="/preguntas-frecuentes" className="nav-menu__link nav-menu__link--submenu">
                    Preguntas frecuentes
                  </Link>
                </li>
                <li className="menu-desktop__nav__item">
                  <button type="button" onClick={() => setShowQuestionsModal(true)} className="nav-menu__link nav-menu__link--submenu js-btn-valoracion-experiencia">
                    Contacto
                  </button>
                </li>
              </ul>
            </div>
          </div>

          <div onClick={toggleActiveClass} className="help-float__button js-btn-help-float">
            <span className="help-float__text">¿Necesitás ayuda?</span>
            <i className="ri-question-line"></i>
          </div>
        </div>

        {/* Back to top */}
        <div onClick={goToTop} className={`back-to-top ${fixedFooterClass} ${showBackToTop ? "fade-in-transition" : "fade-out-transition"}`} aria-label="Volver arriba">
          <i className="ri-arrow-up-line"></i>
        </div>

      </div>

      {showModal && <TermsConditionsModal onCloseModal={() => setShowModal(false)} /> }
      {showQuestionsModal && <QuestionsModal onCloseModal={() => setShowQuestionsModal(false)}/>}
      {showConfirmationCuestionModal && <ConfirmationQuestionsModal questionData={responseQuestionData} onCloseModal={() => setConfirmationCuestionModal(false)} />}
    </>
  );
}

export default Footer;
