import { useState, useContext, useEffect } from "react";
import { useNavigate, Link, useParams, Navigate, useSearchParams } from "react-router-dom";
import logo from "../../assets/images/logo-happier-header.png";
import Alert from "../../components/UI/Alert";
import Loader from "../../components/UI/Loader";
import UserContext from "../../context/UserContext";
import useChangeHandler from "../../hooks/useChangeHandler";
import { axiosInstance } from '../../services';

const LoginBackend = () => {
	const navigate = useNavigate();
	const { corporateUrl } = useParams();

	const [loading, setLoading] = useState(true)
	const [foundCorporate, setFoundCorporate] = useState(true)

	const [loginType, setLoginType] = useState(null)

	const [user, setUser] = useState({
		password: "",
		email: "",
	});

	//SSO login variables
	const [showErrorSSO, setShowErrorSSO] = useState(false)
	const [searchParams] = useSearchParams();
	const code = searchParams.get('code');
	const state = searchParams.get('state');
	const [corporateId, setCorporateId] = useState(null);


	useEffect(() => {
		const controller = new AbortController();

		if(code !== undefined && state !== undefined){
			
			const company_id = localStorage.getItem('company_id')

			const sendCallback = async () => {
			const response = await axiosInstance.get('/unilever/auth0/callback', { 
				params: { 
					code: code,
					state: state, 
					url: corporateUrl,
					corporate_id: company_id
				} 
			}).then(response => {
				setLoading(false);
				if (response?.status === 200) {
					localStorage.removeItem('company_id');
					navigate('/home');
				}else{
					localStorage.removeItem('company_id');
					navigate('/login');
				}
			  }).catch(e => {
					console.log(e);
				  })
			  }

			sendCallback();
		}

		const checkCorporate = async () => {

			const companyUrl = localStorage.getItem('company')

			if (!companyUrl && !corporateUrl) {
				return setFoundCorporate(false)
			} else if (companyUrl && !corporateUrl) {
				return navigate(`/${companyUrl}/login`)
			}

			try {
				const response = await axiosInstance.get(`verify_corporate/${corporateUrl}`, { signal: controller.signal });
				if (response.status === 200) {

					console.log(response?.data);
					if (response?.data?.login_type) {
						setLoginType(response?.data?.login_type)
					}

					if (response?.data?.corporate_id){
						setCorporateId(response?.data?.corporate_id)
						localStorage.setItem('company_id', response?.data?.corporate_id)
					}
					// Guardo la url de la empresa en el localStorage
					localStorage.setItem('company', corporateUrl)

					setLoading(false);

					login();
				}
			} catch (error) {
				console.log(error);
				setFoundCorporate(false)
			}
		}

		checkCorporate();

		return () => controller.abort();
	}, [corporateUrl])

	async function login() {
		try {

			//Reques a api para obtener datos
			const company_id = localStorage.getItem('company_id')
			console.log("company    "+company_id)
			const response = await axiosInstance.get('unilever/auth0/login', { 
				params: { 
					url: corporateUrl, 
					corporate_id: company_id 
				} 
			});
			const { data } = response?.data;

			if (typeof data !== 'undefined') {

				window.location.replace(data);
				
			} else {
				setShowErrorSSO(true);
			}
		} catch (error) {
			console.log(error)
			setShowErrorSSO(true);
		}
	}

	return (
		loading ? <Loader />
			:
			<section style={{ backgroundColor: "#f5f5f5" }}>
				<section className="section section--login">
					
				</section>
			</section>
	);
}
export default LoginBackend;