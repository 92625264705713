const InputAlert = ({ text = '' }) => {
    if (text) {
        return (
            <p className="input-alert">
                <i className="input-alert__icon fa fa-exclamation-circle"></i>{text}
            </p>
        )
    }

    return <></>
}

export default InputAlert