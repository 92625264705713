import React, { Component } from 'react';
import { Link } from 'react-router-dom'

// Tiene que ser class based component si o si
class ErrorBoundary extends Component {
    constructor() {
        super();
        this.state = {
            hasError: false,
            path: null
        }
    }

    componentDidCatch(error) {
        console.log(error)
        const pathName = this.props?.location?.pathname
        this.setState({ hasError: true, path: pathName })
    }

    componentDidUpdate() {
        const pathName = this.props?.location?.pathname

        if (this.state.path !== pathName)
            this.setState({ hasError: false, path: pathName })
    }

    render() {
        if (this.state.hasError) {
            return (
                <section className="section">
                    <div className="section__holder">
                        <div className="section__inner">
                            <div className="section__item">
                                <div className="message-success --center">
                                    <i className="message-success__icon --mb12 ri-alert-line"></i>
                                    <h1 className="message-success__title --mb6"><strong>¡Oops!</strong></h1>
                                    <h2 className="message-success__subtitle --mb30">Se ha producido un error al intentar mostrar <strong>esta página</strong></h2>
                                    {
                                        this.props.global ?
                                            <Link to="/login" className="button button--primary button--medium">
                                                <span>Volver al login</span>
                                            </Link>
                                            :
                                            <>
                                                <h3 className="message-success__text --mb50">Para continuar volvé a cargar la página o regresá <Link to="/home">al home</Link></h3>

                                                <button type="button" className="button button--primary button--medium" onClick={() => this.setState({ hasError: false })}>
                                                    <span>Cargar de nuevo</span>
                                                </button>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
