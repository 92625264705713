
import { Link, useNavigate, useParams, Navigate } from 'react-router-dom'
import Alert from '../../components/UI/Alert';
import { useEffect, useState } from 'react';
import useChangeHandler from '../../hooks/useChangeHandler';
import { axiosInstance } from '../../services';
import logo from "../../assets/images/logo-happier-header.png";
import useValidator from '../../hooks/useValidator';
import useAsyncError from '../../hooks/useAsyncError';
import { generatePassword } from '../../utils/password';


const PasswordRecoveryNew = ({hash, callback}) => {
    
    const initialState = {
        hash:hash,
        newPassword: null,
        confirmNewPassword: null,
        corporate_url: localStorage.getItem('company'),
    }
    
    const navigate = useNavigate();

    const [validator, showValidationMessage] = useValidator()
    const throwError = useAsyncError();
  
    const [loading, setLoading] = useState(true);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [errorAlertText, setErrorAlertText] = useState(null);
  
    const [formData, setFormData] = useState(initialState);
  
    const [validationErrors, setValidationErrors] = useState({});
    const [generatedPassword, setGeneratedPassword] = useState("");
  
    const changeHandler = useChangeHandler(setFormData, setValidationErrors);

    const generateStrongPassword = () => { 
        setGeneratedPassword(generatePassword({}))
    
      }


    const passToogleConfirm = (element, id) => {

        var input = document.getElementById(id);
        var icon = document.querySelectorAll('.form-item__input__icon_right');
    
        if (input.style[0] == "-webkit-text-security") {
          input.style = "";
          icon[element].className = 'form-item__input__icon_right ri-eye-line';
    
        } else {
          input.style = "-webkit-text-security:disc";
          icon[element].className = 'form-item__input__icon_right ri-eye-off-line';
    
        }
    
    }

    const copyPassword = () =>{
        navigator.clipboard.writeText(generatedPassword);
      }

    const saveData = async (e) => {
        e.preventDefault()
    
        setShowSuccessAlert(false)
        setErrorAlertText(null)
    
        if (validator.allValid()) {
          try {
            const response = await axiosInstance.post(`/store_recovery_password`, { ...formData });
            if (response?.status === 200) {
              setShowSuccessAlert(true)
            }
          } catch (error) {
            console.log(error)
    
            const message = error?.response?.data?.message
            const statusCode = error?.response?.status
    
            if (statusCode === 422) {
    
              setErrorAlertText('Ocurrió un error al intentar guardar la contraseña. ' + message)
            } else {
              setErrorAlertText('Ocurrió un error al intentar guardar la contraseña.')
            }
          }
          setValidationErrors({})
        } else {
          setValidationErrors(validator.getErrorMessages())
          showValidationMessage(true)
        }
    }
    return (
        <>
        {showSuccessAlert && <>
          <Alert text="Contraseña modificada con éxito" type="success" />

                <a className="button button--primary button--medium js-btn-datos-personales" onClick={callback} data-sitekey="" data-callback="">
                    <span>Continuar al login</span>
                </a>

        </>}
        {errorAlertText && <Alert text={errorAlertText} type="error" />}
        {!showSuccessAlert && <>
          <h2 className="section__title --mb12">Generar nueva contraseña</h2>
          <p className="section__text section__text--medium --mb40">Escribe tu nueva contraseña.</p>
            <form class="modal__form modal__form--login" action="" method="post" autoComplete="off">
                <div className="form-item"></div>

                  <div className="form-item">
                    <div className="form-item --footer --mb0" style={{ alignItems: 'flex-start'}}>
                      <label htmlFor="new_password" className="form-item__label">Nueva contraseña</label>
                      <a className="button button--link button--medium button--link-color button--no-height" onClick={()=>{generateStrongPassword()}}>
                          <i className="ri-key-2-fill"></i>Generar contraseña
                      </a>
                    </div>
                    <div className="form-item__input">
                      <i className="form-item__input__icon_right ri-eye-off-line" onClick={()=>{passToogleConfirm(0, "new_password")}}></i>                  
                      <input type="text" id="new_password" name="newPassword" autoComplete="new-password" style={{ WebkitTextSecurity: 'disc' }} className={`input input--border ${validationErrors.new_password && 'input--error'}`} onChange={changeHandler} required />
                      {!validationErrors.last_name && <span className="form-item__input__focus"></span>}
                    </div>
                    {validator.message('new_password', formData.newPassword, 'required')}
                    <p class="section__text section__text--small --mt9 --c-grey">La contraseña debe tener un mínimo de 12 caracteres. La contraseña debe contener números, letras mayúsculas, letras minúsculas y simbolos.</p>
                </div>

                <div className="form-item">
                  <label htmlFor="repeat_new_password" className="form-item__label">Repetir nueva contraseña</label>
                  <div className="form-item__input">
                    <input type="text" id="repeat_new_password" name="confirmNewPassword" autoComplete="new-password" style={{ WebkitTextSecurity: 'disc' }} className={`input input--border ${validationErrors.repeat_new_password && 'input--error'}`} onChange={changeHandler} required />
                    <i className="form-item__input__icon_right ri-eye-off-line" onClick={()=>{passToogleConfirm(1, "repeat_new_password")}}></i>                  
                    {!validationErrors.alias && <span className="form-item__input__focus"></span>}
                  </div>
                  {validator.message('repeat_new_password', formData.confirmNewPassword, 'required')}
                </div>
                
                
                {(generatedPassword != "") &&
                  /* No use el componente "Alert" ya que por su forma de estar armado, no cumplia con lo que necesitaba */
                  <>
                  <div class="alert alert--atention --mb25">
                    <span className="alert__text">
                      Esta es tu nueva contraseña generada: <strong>{generatedPassword}</strong><br/> 
                      Copiala en un lugar seguro antes de cambiarla.<br/>
                      <a onClick={copyPassword} className="button button--link button--medium button--bold button--link-color button--no-height --mt12">
                        <i className="ri-file-copy-line"></i>Copiar contraseña
                      </a>
                    </span>
                  </div>
                  </>
                } 
                { /*
                <div className="form-item --fullwidth --mb25" style={{ display:'inline' }}>
                       <Alert text='La contraseña debe tener un mínimo de 12 caracteres.<br> La contraseña debe contener números, letras mayúsculas, letras minúsculas y simbolos.' type="atention" /> 
                </div> */ 
                }
                
                {
                  <div className="form-item --footer --center --mt25">
                    <button type="submit" className="button button--primary button--medium js-btn-datos-personales" onClick={saveData} data-sitekey="" data-callback="">
                      <span>Cambiar contraseña</span>
                    </button>
                  </div>
                }
            </form>

            </>
            }
      </>
    );
}

export default PasswordRecoveryNew;