import useSetActive from "../../hooks/useSetActive";
import { useContext, useRef } from "react";
import useOutsideHandler from "../../hooks/useOutsideHandler";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../context/UserContext";
import { formatPoints } from "../../utils"
import { axiosInstance } from "../../services";
import AvatarImage from "../UI/AvatarImage";

export const Profile = () => {
    const navigate = useNavigate()

    const [activeClass, toggleActiveClass] = useSetActive();
    const { user, setUser } = useContext(UserContext);

    const wrapperRef = useRef(null);
    useOutsideHandler(wrapperRef, toggleActiveClass, 'MoreExperiences')

    const logoutHandler = async () => {
        await axiosInstance.post("/logout")
        setUser({})
        localStorage.removeItem('logged');
        localStorage.removeItem('afterLogin');
        navigate('/login')
    }

    return (
        <div className="nav-menu__dropdown" onClick={toggleActiveClass} ref={wrapperRef}>
            <div className="nav-login js-btn-logged">
                <AvatarImage name={user.name}/>
                <div className="nav-login__content">
                    <p className="points points--panel">{formatPoints(user.points)} <span>puntos</span></p>
                    <i className={`nav-login__content__arrow ri-arrow-down-s-line js-btn-logged-icon ${activeClass}`}></i>
                </div>
            </div>

            <div className={`menu-desktop menu-desktop--logged js-menu-logged ${activeClass}`}>
                <div className={`menu-desktop__content js-menu-logged-content ${activeClass}`}>
                    <div className="menu-desktop__top">
                        <p className="nav-login__title">{user.name}</p>
                    </div>
                    <ul className={`menu-desktop__nav`}>
                        <li className="menu-desktop__nav__item">
                            <Link to="/perfil" className="nav-menu__link nav-menu__link--submenu">
                                <i className="nav-menu__link__icon ri-user-line"></i>Mi perfil
                            </Link>
                        </li>
                        <li className="menu-desktop__nav__item">
                            <Link to="/mis-puntos" className="nav-menu__link nav-menu__link--submenu">
                                <i className="nav-menu__link__icon ri-coin-line"></i>Mis puntos
                            </Link>
                        </li>
                        <li className="menu-desktop__nav__item">
                            <Link to="/mis-consumos" className="nav-menu__link nav-menu__link--submenu">
                                <i className="nav-menu__link__icon ri-file-list-3-line"></i>Mis Consumos
                            </Link>
                        </li>
                        <li className="menu-desktop__nav__item">
                            <Link to="/favoritos" className="nav-menu__link nav-menu__link--submenu">
                                <i className="nav-menu__link__icon ri-heart-line"></i>Mis Favoritos
                            </Link>
                        </li>
                    </ul>

                    <ul className="menu-desktop__bottom">
                        <li className="menu-desktop__nav__item">
                            <button onClick={logoutHandler} type="button" className="nav-menu__link nav-menu__link--submenu">
                                <i className="nav-menu__link__icon ri-logout-circle-line"></i>Cerrar sesión
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

