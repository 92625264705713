import { useEffect } from "react";

const useOutsideHandler = (ref, toggleActiveClass, component = '') => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        toggleActiveClass(false);

        let elements;

        switch (component) {
          case 'Select':
          case 'MoreExperiences':
            elements = [
              ...document.getElementsByClassName('select__menu'),
              ...document.getElementsByClassName('select__button__arrow'),
              ...document.getElementsByClassName('js-menu-logged'),
              ...document.getElementsByClassName('js-btn-select'),
              ...document.getElementsByClassName('js-select-overlay'),
            ];
            break;

          default:
            elements = [
              ...document.getElementsByClassName('js-navmenu'),
              ...document.getElementsByClassName('js-btn-navmenu i'),
              ...document.getElementsByClassName('js-navmenu-content'),
              ...document.getElementsByClassName('js-menu-logged'),
              ...document.getElementsByClassName('js-btn-logged-icon'),
              ...document.getElementsByClassName('js-menu-logged-content'),
              ...document.getElementsByClassName('js-alert'),
              ...document.getElementsByClassName('js-search'),
              ...document.getElementsByClassName('js-desktop-menu-overlay'),
              ...document.getElementsByClassName('js-help-float'),
              ...document.getElementsByClassName('js-help-float-content'),
            ];
            break;
        }

        for (const el of elements) {
          el.classList.remove('--active');
        }
      }
    }

    // Bind the event listener
    document.getElementById('back-to-top').addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.getElementById('back-to-top').removeEventListener("click", handleClickOutside);
    };
  }, [ref, toggleActiveClass, component]);
}

export default useOutsideHandler;