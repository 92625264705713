import React from 'react'
import useScrollToId from '../hooks/useScrollToId'

const TermsConditions = () => {
    useScrollToId()

    return (
        <>
            <div className="section__item">
                <h2 id="politicas-privacidad" className="section__title --mb25">Políticas de Privacidad</h2>
                <p className="section__text section__text--legal">
                    Happier es un programa desarrollado e implementado por Bonva S.A., CUIT 33-71023791-9, empresa titular de la marca Fanbag y a la que, en lo sucesivo, se la llamará "Fanbag".<br /><br />
                    Fanbag es una empresa que se compromete con la normativa legal sobre la protección de datos personales de sus usuarios.<br /><br />
                    Este documento es parte integrante de las Condiciones Generales de Happier. Al momento de aceptar las Condiciones Generales, o al brindar sus datos personales voluntariamente a Fanbag por cualquiera de sus medios, el usuario manifiesta conocer y aceptar que el destinatario de datos personales es Fanbag y sus empresas afiliadas, quienes podrán utilizar los mismos a los fines del desarrollo de su actividad comercial y de mejorar sus productos y servicios. Asimismo, los datos personales brindados por el usuario podrán ser utilizados por Fanbag, a los efectos de elaborar estadísticas generales, realizarle consultas, encuestas, rifas, o informarle de otros productos y servicios o comunicarse con él.<br /><br />
                    Si Ud. no desea brindar sus datos, existe la posibilidad de que no tenga acceso a ciertos servicios brindados por Fanbag.<br /><br />
                    El usuario garantiza la veracidad y certeza de los datos personales que brinda voluntariamente a través de cualquiera de los medios en que Fanbag se los solicitase, en virtud de lo cual Fanbag no asume ninguna responsabilidad en el caso de inexactitud de éstos.<br /><br />
                    El usuario que brinde sus datos personales podrá en cualquier momento ejercer el derecho de acceso, rectificación y supresión de sus datos en un todo de acuerdo con la normativa vigente de protección de datos personales a través de las oficinas de Fanbag.
                </p>

                <p className="section__text section__text--legal">
                    <strong>Uso de cookies</strong>
                    Fanbag utiliza cookies a fin de hacer la página más “amigable al usuario”, permitiéndole a Ud. utilizar servicios sin tener que ingresar repetidamente la información de registro.
                </p>

                <p className="section__text section__text--legal">
                    <strong>Uso de la página</strong>
                    Fanbag mantiene un seguimiento de las direcciones IP de los hosts con fines de administración y seguridad del sistema. También monitoreamos el tráfico de la página guardando registros de las vistas de la página que nos permite planear crecimiento (por ejemplo, agregando nuevos servidores).
                </p>

                <p className="section__text section__text--legal">
                    <strong>Divulgación a terceros</strong>
                    Tras su reserva, compra o canje de puntos en esta página, Fanbag suministra la información personal relevante al proveedor del servicio contratado, como hoteles, centros de bienestar, restaurantes, y otros involucrados para completar las transacciones. Nuestros proveedores que brindan servicios externos de suministro, mantenimiento y comercialización de esta página tienen acceso a información personal pero están sujetos contractualmente a mantener la confidencialidad y se les prohíbe la utilización o divulgación no autorizada de la información a la cual tienen acceso.<br /><br />
                    Para proteger los derechos de Fanbag o sus usuarios, o si la ley lo requiere, Fanbag divulgará información personal a funcionarios de gobierno o entidades legales.<br /><br />
                    Fanbag no vende información personal a terceros y no tiene intención de hacerlo en el futuro.
                </p>

                <p className="section__text section__text--legal">
                    <strong>Vínculos externos</strong>
                    Esta página puede tener enlaces a otras páginas. Fanbag no será responsable por la confidencialidad, por los contenidos, materiales, acciones y/o servicios prestados por los mismos, ni por daños o pérdidas ocasionadas por la utilización de los mismos, sean causadas directa o indirectamente. Si Ud. visita esas otras páginas web, Fanbag lo invita a revisar sus prácticas y políticas de confidencialidad.
                </p>

                <p className="section__text section__text--legal">
                    <strong>Seguridad</strong>
                    Esta página contiene medidas de seguridad para protección contra pérdida, uso indebido, o alteración de la información.
                </p>

                <p className="section__text section__text--legal">
                    <strong>Contactarnos</strong>
                    Si tiene preguntas, observaciones o dudas sobre nuestra política de privacidad, nos puede escribir a <a href="mailto:infohappier@fanbag.com.ar">infohappier@fanbag.com.ar</a> o en la opción “Contacto”, en el pie de página de nuestra plataforma de beneficios.
                </p>

                <p className="section__text section__text--legal">
                    <strong>Modificaciones</strong>
                    Cualquier modificación en nuestra política de privacidad será publicada en esta página.
                </p>

                <hr className="section__separate" />

                <h2 id="condiciones-generales" className="section__title --mt40 --mb25">Condiciones generales del Programa Happier</h2>
                <p className="section__text section__text--legal">
                    <strong>A. EL PROGRAMA</strong>
                    Happier (en adelante, el “Programa”) es un programa de acumulación de puntos desarrollado e implementado por Bonva S.A., una sociedad comercial constituida en la República Argentina, con domicilio en Catulo Castillo 2960, CP 1244, Ciudad Autónoma de Buenos Aires, CUIT 33-71023791-9 (en adelante, la “Agencia”).<br /><br />
                    Las presentes condiciones generales para la participación en el Programa (las “Condiciones Generales”) se considerarán como complemento y parte integrante de las Condiciones Generales de la Agencia.<br /><br />
                    La Agencia se reserva el derecho de dejar sin efecto el Programa o de modificar total o parcialmente los términos y condiciones del mismo en cualquier momento.
                </p>

                <p className="section__text section__text--legal">
                    <strong>B. CARÁCTER VINCULANTE</strong>
                    El adquirente, titular y/o usuario legitimado de los servicios incluidos en el Programa (el “Usuario”) queda notificado de las presentes Condiciones Generales y de las condiciones generales de la Agencia, las cuales acepta de forma expresa como condición necesaria para adquirir, contratar y/o utilizar tales y/o ejercer los derechos adquiridos en relación a los mismos.<br /><br />
                    Estas Condiciones Generales tienen un carácter obligatorio y vinculante. En caso de no aceptarlas, el Usuario no podrá hacer uso de los beneficios ofrecidos en el Programa. Al aceptar las Condiciones Generales, el Usuario declara que ha leído, entendido y consentido las mismas.
                </p>

                <p className="section__text section__text--legal">
                    <strong>C. LOS PUNTOS</strong>
                    El Usuario acumulará puntos (en adelante, los “Puntos”) mediante la asignación de Puntos que haga en su favor la entidad que contrató el Programa en beneficio del Usuario (en adelante, la “Empresa”).<br /><br />
                    Los Puntos serán cargados en la cuenta que la Empresa creará a nombre del Usuario (en adelante, la “Cuenta”). Cada Usuario tendrá una -y no más de una- Cuenta.<br /><br />
                    Los Puntos acumulados en el Programa no tienen valor monetario alguno y no podrán ser canjeados por dinero, sino únicamente por Servicios, de acuerdo al valor de los mismos que se exhibirá en la plataforma. Cada vez que el Usuario canjee Puntos por un Servicio, serán descontados de la Cuenta los Puntos correspondientes al mismo, de acuerdo al valor informado.<br /><br />
                    Cada Punto tendrá una vigencia de 24 meses desde la fecha de acreditación en la Cuenta. Transcurrido dicho plazo, si un Punto no se canjeó, vencerá, previa notificación al Usuario con 30 días de anticipación a la fecha de vencimiento.<br /><br />
                    Los puntos acumulados en el Programa no constituyen propiedad del Usuario, por lo que también caducan por: (a) la finalización del Programa; (b) la terminación de la contratación del Programa por parte de la Empresa; (c) la finalización del vínculo laboral o comercial entre el Usuario y la Empresa; o (d) por cualquier otro motivo que la Agencia o la Empresa consideren suficiente, de forma unilateral, sin necesidad de demostrar causa. En estos supuestos, la Agencia no estará obligada a dar preaviso alguno al Usuario, quien no tendrá derecho a reclamo alguno contra la Agencia.
                </p>

                <p className="section__text section__text--legal">
                    <strong>D. LOS SERVICIOS</strong>
                    Los Usuarios podrán canjear los Puntos acumulados por distintos productos y/o servicios (en adelante, tanto productos como servicios serán denominados “Servicios”), siempre que hayan acumulado la cantidad de Puntos necesaria para la obtención de los mismos. La Agencia actuará en todo momento en calidad de intermediaria en la prestación de los Servicios cuya prestación efectiva será realizada por terceros (el/los “Prestador/es”). Consecuentemente se aplicarán a la efectiva prestación de tales Servicios las condiciones de quien/es sea/n su/s Prestador/es. La Agencia se reserva el derecho de modificar, agregar o cancelar cualquier Servicio sin previo aviso, como así también los Puntos necesarios para acceder a cada uno de ellos.<br /><br />
                    Los Servicios estarán sujetos a disponibilidad. Los Servicios del Programa estarán detallados en www.happier.com.ar o en el subdominio del Programa correspondiente a la Empresa o, de corresponder, en el dominio de la propia Empresa (en adelante, el “Sitio”), donde los Usuarios podrán consultar sus textos completos. El Usuario deberá verificar las condiciones y los datos correspondientes a cada Servicio, siendo su responsabilidad formular las consultas, requerir las aclaraciones o evacuar sus dudas, con carácter previo al canje de los Puntos. En caso de que en el sitio general del Programa y el subdominio correspondiente a la Empresa que asignó Puntos a un Usuario -o, de corresponder, el dominio de la propia Empresa- existan diferencias en la descripción de un Servicio, frente al Usuario serán aplicables las condiciones descriptas en estos últimos.<br /><br />
                    Las características de los Servicios y la modalidad de uso de los mismos por parte del Usuario se detallan, además, en los distintos soportes digitales que la Agencia enviará al Usuario cuando éste canjee los Puntos (voucher digital, correo electrónico o cualquier otro soporte que sea utilizado). Dicho soporte será emitido por la Agencia, estará identificado con un algoritmo de seguridad y servirá de comprobante a ser entregado al Prestador al momento de hacer uso del Servicio.<br /><br />
                    Los Servicios pueden sufrir modificaciones, que, si llegaran a producirse, serán informadas en el Sitio.<br /><br />
                    En ciertos casos, el uso de los Servicios se sujetará a determinados plazos de vigencia, así como también se excluirá el uso de los mismos en determinados días o períodos, según se indique en cada caso. Si entre el momento del canje de los Puntos y la reserva, o entre la reserva y la fecha en la que debería hacerse uso de la misma, el Servicio pasase de estar activo a inactivo, el Usuario verá restituidos los Puntos canjeados, sin derecho a compensaciones adicionales.
                </p>

                <p className="section__text section__text--legal">
                    <strong>E. TIPOS DE SERVICIOS</strong>
                    Cuando el Servicio elegido sea una experiencia, el Usuario recibirá una confirmación de reserva identificada con un número, que el Usuario podrá exhibir al prestador en formato digital o físico (impreso), en caso de que el prestador así lo requiera.<br /><br />
                    Cuando el Servicio elegido sea una Gift-Card, la misma consistirá en el envío de soporte digital que determine el Prestador (por ejemplo, voucher, archivo PDF, link a una URL, correo electrónico, entre otros). Las Gift-Cards podrán ser utilizadas inmediatamente luego de su activación, y únicamente en los productos y/o servicios brindados por los Prestadores adheridos. Las condiciones de uso y la vigencia de las Gift-Cards serán informadas al Usuario al momento de su adquisición. Vencida su vigencia, la Gift-Card ya no podrá ser utilizada, y el Usuario no tendrá derecho a la restitución de los Puntos ni al pago del monto no utilizado. Las Gift-Cards sólo pueden ser utilizadas en una única oportunidad. Si fueran canjeadas por un bien o servicio de valor inferior al de la Gift-Card, el Usuario perderá el saldo sin derecho a reclamo alguno. La posibilidad de canjearlas por bienes o servicios de valor superior al de la Gift-Card mediante el pago de la diferencia por parte del Usuario dependerá de que dicha posibilidad sea aceptada por el respectivo Prestador. En el uso de las Gift-Cards y su eventual canje por bienes o servicios regirán los términos y condiciones del Prestador correspondiente, por lo que el Usuario, si llegase a tener consultas o reclamos, deberá comunicarse con dicho Prestador.
                </p>

                <p className="section__text section__text--legal">
                    <strong>F. CUESTIONES NO INCLUIDAS</strong>
                    No quedarán incluidas en los Servicios las prestaciones que no estén explícitamente incluidas en la descripción de los mismos. Sin que ello implique limitación, no se entenderán incluidos en los Servicios: i) costos de traslados desde y/o hasta las instalaciones en donde se prestarán los servicios que forman parte de los Servicios, ni los servicios extras, bebidas, lavado de ropa, propinas, tasas sobre servicios, IVA y otros impuestos, actuales y/o futuros, ni ningún servicio que no se encuentre expresamente indicado en la orden de servicio emitida por la Agencia; ii) estadías, comidas y/o gastos adicionales no descriptos en la oferta; y iii) los gastos e intereses en las operaciones a crédito.
                </p>

                <p className="section__text section__text--legal">
                    <strong>G. LIMITACIONES AL DERECHO DE PERMANENCIA</strong>
                    La Agencia reconoce el derecho del Prestador de hacer que el Usuario abandone las instalaciones si su conducta, modo de obrar, estado de salud u otras razones graves a juicio del Prestador provoque peligro o cause molestias a los restantes usuarios o pueda malograr la normal prestación de los servicios.
                </p>

                <p className="section__text section__text--legal">
                    <strong>H. RESPONSABILIDAD</strong>
                    La Agencia declara expresamente que actúa en el carácter de intermediaria en la prestación de los Servicios. A partir del momento en que los Puntos sean canjeados por el Usuario, se entenderá que la vinculación entre el Usuario y la Agencia ha concluido. En consecuencia, cualquier inconveniente que se pueda producir por incumplimientos del Prestador deberá ser resuelto entre el Usuario y el Prestador mismo, limitándose la responsabilidad de la Agencia a colaborar y a realizar los mejores esfuerzos para que la situación se resuelva de la forma más satisfactoria posible. El Prestador será el único responsable por los riesgos y/o daños que pudieran suscitarse al momento de ser utilizados los Servicios, renunciando el Usuario a reclamar a la Agencia por cualquier acción y/u omisión del Prestador que le pueda haber llegado a ocasionar algún perjuicio.<br /><br />
                    Cualquier fraude o abuso relacionado con la acumulación de Puntos, atribuidos al Usuario o a la Empresa, tendrá por resultado la pérdida de los Puntos acumulados, así como la cancelación de la Cuenta, sin derecho a reclamo por parte del Usuario.
                </p>

                <p className="section__text section__text--legal">
                    <strong>I. MODIFICACIONES, ALTERACIONES, CANCELACIONES O DESISTIMIENTOS POR PARTE DEL USUARIO</strong>
                    Salvo declaración expresa en contrario, los Prestadores podrán variar la cantidad e identidad de los Servicios adquiridos por el Usuario mediante canje de Puntos, siempre que dicho cambio sea por Servicios de similar categoría y/o valor. En tal caso, los Usuarios no tendrán derecho a reclamo ni indemnización alguna.<br /><br />
                    Una vez canjeados los Puntos por Servicios consistentes en servicios que deban ser reservados, dicha reserva será realizada directamente por el Usuario frente al Prestador. Confirmada la reserva, será exclusiva responsabilidad del Usuario acordar cada modificación de fecha u hora u otras condiciones de uso en forma directa con el Prestador respectivo, liberando a la Agencia de responsabilidades sobrevivientes. En caso de que el Usuario realice una reserva y desee cancelarla o reprogramarla, el Usuario se hará cargo de los costos en los que incurra el Prestador relacionados con su reserva, si la referida reprogramación o cancelación no se comunicasen respetando el plazo mínimo informado en el servicio pertinente.<br /><br />
                    En caso de arrepentimiento, el Usuario podrá recuperar los Puntos canjeados únicamente a discreción de la Agencia. La negativa de la Agencia a restituir los Puntos canjeados no dará derecho al Usuario a reclamo alguno. Se aclara expresamente que en ningún caso serán restituidos los Puntos canjeados por Gift-Cards.
                </p>

                <p className="section__text section__text--legal">
                    <strong>J. SEPARABILIDAD. NO RENUNCIA</strong>
                    En caso de que cualquiera de las disposiciones previstas en estas Condiciones Generales fuera declarada nula o ineficaz por cualquier tribunal competente, la validez de las restantes condiciones no resultará afectada.
                    El hecho que la Agencia omita la exigencia de un término o condición en particular, no constituye una renuncia por parte de la Agencia a dicho término o condición.
                </p>
            </div>
        </>
    )
}

export default TermsConditions