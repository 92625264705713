import { useNavigate } from "react-router";

const useRedirectToLink = () => {
    const navigate = useNavigate()
    
    const redirect = link => {
        if(!link) return;

        // Chequeo si el link es local o externo para usar navigate de react-router o redireccion nativa
        const siteUrl = window.origin
        let redirectUrl = null

        if (link.includes(siteUrl)) {
            redirectUrl = link.replace(siteUrl, "") // Quito el root y obtengo la url despues de la /
            navigate(redirectUrl, {replace: true})
        } else {
            window.open(link, '_blank');
        }
    }

    return redirect;
};

export default useRedirectToLink;