import React from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";
import ScrollToTop from "./components/UI/ScrollToTop";
import Layout from "./components/Layout/Layout";
import ProfileLayout from "./components/Profile/ProfileLayout";
import Loader from "./components/UI/Loader";
import { useEffect } from "react";
// Componentes declarados para carga directa
import Login from "./pages/Auth/Login";
import ErrorBoundary from './components/UI/ErrorBoundary';
import FaqLayout from './components/FAQ/FaqLayout';
import LoginUnilever from './pages/Auth/LoginUnilever';
import LoginBackend from './pages/Auth/LoginBackend';
import FirstLogin from "./pages/Auth/PasswordReset";

// Componentes declarados para lazy loading
const Home = React.lazy(() => import("./pages/Home"))
const PasswordRecovery = React.lazy(() => import("./pages/Auth/PasswordRecovery"))
const NotFound = React.lazy(() => import("./pages/NotFound"))

const ExperiencesList = React.lazy(() => import("./pages/Experiences/ExperiencesList"))
const ExperienceDetail = React.lazy(() => import("./pages/Experiences/ExperienceDetail"))
const Reservation = React.lazy(() => import("./pages/Experiences/Reservation"))

const GCList = React.lazy(() => import("./pages/Giftcards/GCList"))
const GCDetail = React.lazy(() => import("./pages/Giftcards/GCDetail"))
const GCReservation = React.lazy(() => import("./pages/Giftcards/GCReservation"))

const Profile = React.lazy(() => import("./pages/Profile/Profile"))
const MyPoints = React.lazy(() => import("./pages/Profile/MyPoints"))
const GiftPoints = React.lazy(() => import("./pages/Profile/MyPoints"))
const BuyPoints = React.lazy(() => import("./pages/Profile/MyPoints"))
const Purchases = React.lazy(() => import("./pages/Profile/Purchases"))
const Favorites = React.lazy(() => import("./pages/Profile/Favorites"))

const SearchResult = React.lazy(() => import("./pages/SearchResult/SearchResult"))

const TermsConditions = React.lazy(() => import("./pages/TermsConditions"))
const Faq = React.lazy(() => import("./pages/Faq"))

const Benefits = React.lazy(() => import("./pages/Benefits/Benefits"))
const BenefitsCategory = React.lazy(() => import("./pages/Benefits/BenefitCategory"))
const BenefitsNotFound = React.lazy(() => import("./pages/Benefits/BenefitNotFound"))

const PageLoader = ({ children }) => {
  return (
    <React.Suspense fallback={<Loader />}>
      {children}
    </React.Suspense>
  )
}

const LayoutWithErrors = () => {
  return (
    <ErrorBoundary global={true}>
      <Layout />
    </ErrorBoundary>
  )
}

const clearCacheData = () => {
  if('caches' in window){
    caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        });
    }
};

const App = () => {
  useEffect(() => {
    clearCacheData();
  });

  const currentPathname = window.location.pathname;
  const isGiftPage = currentPathname.includes("/regalo-de-puntos");
  const navigate = useNavigate()
  
  if (isGiftPage) {
    localStorage.setItem('afterLogin', window.location.pathname);
  }

  useEffect(()=>
  {
    if (currentPathname.includes("/login") && localStorage.getItem('logged')){
      navigate("/home")
  }
  },[])

  return (
    <ScrollToTop>
      <Routes>
        <Route path="/login" element={<Login />}></Route> {/*Login para redirigir*/}
        <Route path="/:corporateUrl/login" element={<Login />}></Route> {/*Login para empresa*/}
        <Route path="/:corporateUrl/auth0/callback" element={<LoginUnilever />}></Route> {/*Login para empresa*/}
        <Route path="/:corporateUrl/auth0/login" element={<LoginBackend />}></Route> {/*Login para empresa*/}
        <Route path="/:corporateUrl/recuperar-contrasena/:hash" element={<Login />}></Route>
        <Route path="/:corporateUrl/login/passwordReset" element={<FirstLogin />}></Route> {/*Login para empresa*/}

  

        <Route exact path="/" element={<LayoutWithErrors />}>
          <Route index element={<PageLoader><Home /></PageLoader>} />
          <Route index path="/home" element={<PageLoader><Home /></PageLoader>}></Route>

          <Route index path="/busqueda" element={<PageLoader><SearchResult /></PageLoader>}></Route>


          {/* Experiencias */}
          <Route path="/experiencias">
            <Route index element={<PageLoader><ExperiencesList /></PageLoader>}></Route>
            <Route path=":categoryUrl" element={<PageLoader><ExperiencesList /></PageLoader>}></Route>
            <Route path="detalle/:experienceId" element={<PageLoader><ExperienceDetail /></PageLoader>}></Route>
            <Route path="reservar/:experienceId" element={<PageLoader><Reservation /></PageLoader>}></Route>
          </Route>

          {/* Giftcards */}
          <Route path="/giftcards">
            <Route index element={<PageLoader><GCList /></PageLoader>}></Route>
            <Route path="detalle/:giftcardId" element={<PageLoader><GCDetail /></PageLoader>}></Route>
            <Route path="reservar/:giftcardId" element={<PageLoader><GCReservation /></PageLoader>}></Route>
          </Route>

          {/* Profile */}
          <Route path="/" element={<ProfileLayout />}>
            <Route path="/perfil" element={<PageLoader><Profile /></PageLoader>}></Route>
            <Route path="/mis-puntos" element={<PageLoader><MyPoints /></PageLoader>}></Route>
            <Route path="/mis-puntos/movimientos/" paramOption={'Transactions'} element={<PageLoader><MyPoints /></PageLoader>}></Route>
            <Route path="/mis-puntos/compra-de-puntos/" element={<PageLoader><BuyPoints paramOption={'BuyPoints'} /></PageLoader>}></Route>
            <Route path="/mis-puntos/compra-de-puntos/:hash" element={<PageLoader><BuyPoints paramOption={'BuyPoints'} /></PageLoader>}></Route>
            <Route path="/mis-puntos/regalo-de-puntos/" element={<PageLoader><BuyPoints paramOption={'GiftPoints'} /></PageLoader>}></Route>
            <Route path="/mis-puntos/regalo-de-puntos/:giftbagId" element={<PageLoader><GiftPoints /></PageLoader>}></Route>
            <Route path="/mis-consumos" element={<PageLoader><Purchases /></PageLoader>}></Route>
            <Route path="/favoritos" element={<PageLoader><Favorites /></PageLoader>}></Route>
          </Route>

          {/* FAQ y Términos y condiciones */}
          <Route path="/" element={<FaqLayout />}>
            <Route index path="/terminos-condiciones" element={<PageLoader><TermsConditions /></PageLoader>}></Route>
            <Route index path="/preguntas-frecuentes" element={<PageLoader><Faq /></PageLoader>}></Route>
          </Route>


          {/* Benefits */}
          <Route path="/">
            <Route index path="/benefits" element={<PageLoader>< Benefits /></PageLoader>}></Route>
            <Route index path="benefits/:slug" element={<PageLoader>< BenefitsCategory /></PageLoader>}></Route>
            <Route index path="benefits/not-found" element={<PageLoader>< BenefitsNotFound /></PageLoader>}></Route>
          </Route>

          {/* catch all */}
          <Route path="*" element={<PageLoader><NotFound /></PageLoader>}></Route>

        </Route>

      </Routes>
    </ScrollToTop>
  );
}

export default App;
