import axios from "axios";

let headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json'
};

axios.defaults.withCredentials = true;

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers,
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const companyUrl = localStorage.getItem('company')

    if (error?.response?.status === 401 && window.location.pathname !== '/not-found' && !(window.location.pathname.includes('login'))) {
        localStorage.removeItem('logged');
        window.location.replace(`${window.location.origin}/${companyUrl ? (companyUrl + '/') : ''}login`);
    }

    return Promise.reject(error);
  }
);