import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import useOutsideHandler from "../../hooks/useOutsideHandler";
import useSetActive from "../../hooks/useSetActive";

export const Search = () => {
    const navigate = useNavigate()

    const [activeClass, toggleActiveClass] = useSetActive();
    const [text, setText] = useState('');

    const inputRef = useRef(null);

    const wrapperRef = useRef(null);
    useOutsideHandler(wrapperRef, toggleActiveClass);

    const searchHandler = (e = null) => {
        if (e.type === 'click' || (e?.key === 'Enter')) {
            if (text.trim() !== '') {
                toggleActiveClass(false)
                const searchText = text
                setText('')
                navigate(`/busqueda/?q=${searchText}`, { state: { reset: true } }) // Paso un state vacio para que haga un refresh al listado si la busqueda es igual
                // window.location.href = window.location.origin + `/busqueda/?q=${searchText}`;
            } else {
                inputRef.current.focus()
            }
        } else if ((e?.key === 'Escape')) {
            toggleActiveClass(false)
        }
    }

    useEffect(() => {
        if (activeClass === '--active') {
            // Sin setTimeout no funciona
            setTimeout(() => {
                inputRef.current.focus()
            }, 100)
        }
    }, [activeClass])

    return (
        <div className="nav-icon__dropdown --search" ref={wrapperRef}>
            <div className="nav-icon__item js-btn-search" onClick={toggleActiveClass}>
                <i className="nav-icon__item__icon ri-search-line"></i>
            </div>
            <div className={`menu-search ${activeClass}`}>
                <div className="menu-search__input">
                    <i className="menu-search__input__icon ri-search-line" onClick={searchHandler}></i>
                    <input
                        ref={inputRef}
                        type="text"
                        onKeyDown={searchHandler}
                        onChange={(e) => setText(e.target.value)}
                        value={text}
                        className="input input--radius input--icon"
                        placeholder="Buscar"
                        autoComplete="off"
                        aria-label="" />
                </div>
            </div>
        </div>
    );
}

