import { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator'
import InputAlert from '../components/UI/InputAlert';

const useValidator = () => {
  const [show, setShow] = useState(false)

  SimpleReactValidator.addLocale('es', {
    accepted: 'Este campo debe ser aceptado.',
    after: 'Este campo debe ser una fecha posterior a :date.',
    after_or_equal: 'Este campo debe ser una fecha posterior o igual a :date.',
    alpha: 'Este campo sólo debe contener letras.',
    array: 'Este campo debe ser un conjunto.',
    before: 'Este campo debe ser una fecha anterior a :date.',
    before_or_equal: 'Este campo debe ser una fecha anterior o igual a :date.',
    between: 'Este campo tiene que estar entre :min - :max:type.',
    boolean: 'Este campo debe tener un valor verdadero o falso.',
    date: 'Este campo no es una fecha válida.',
    date_equals: 'Este campo debe ser una fecha igual a :date.',
    email: 'Este campo no es un correo válido',
    in: 'Este campo es inválido :values.',
    integer: 'Este campo debe ser un número entero.',
    max: 'Este campo no debe ser mayor a :max:type.',
    min: 'El tamaño de este campo debe ser de al menos :min:type.',
    not_in: 'Este campo es inválido :values.',
    not_regex: 'El formato de este campo no es válido.',
    numeric: 'Este campo debe ser numérico.',
    regex: 'El formato de este campo es inválido.',
    required: 'Este campo es obligatorio.',
    size: 'El tamaño de este campo debe ser :size:type.',
    string: 'Este campo debe ser una cadena de caracteres.',
    url: 'El formato este campo es inválido.',
    validUser: 'El usuario seleccionado debe ser válido',
  });

  const validator = new SimpleReactValidator({
    element: message => <InputAlert text={message} />, 
    locale: 'es' 
  })

  if (show) {
    validator.showMessages()
  }

  return [validator, setShow]
}

export default useValidator