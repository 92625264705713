// Hook para actualizar un objecto
const useChangeHandler = (setState, setValidationErrors = null) => {

    const changeHandler = e => {
        setState((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            };
        });
        if (setValidationErrors) {
            setValidationErrors(prev => {
                return {
                    ...prev,
                    [e.target.name]: null,
                }
            })
        }
    };

    return changeHandler;
};

export default useChangeHandler;