import logo from '../../assets/images/logo-happier.png'

import FirstLoginForm from "../../components/Auth/PasswordResetForm";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";

const PasswordReset = () => {

    const { state } = useLocation()
    const { hash, user_id, date, corporateUrl } = state ?? {}
    const navigate = useNavigate();

    useEffect(() => {
        if(!hash || !user_id ||!date ||!corporateUrl){
            navigate('/login');
        }
    }, []);

    return (
        <section style={{backgroundColor: "#f5f5f5"}}>
            <section className="section section--login">
                <div className="section__holder section__holder--login">
                    <div className="modal modal--login --active --mb40">
                        <div className="modal__inner modal__inner--login --active">
                            <FirstLoginForm hash={hash} user_id={user_id} date={date} corporateUrl={corporateUrl}></FirstLoginForm>
                        </div>
                    </div>
                    <div className="footer__item --center">
                        <div className="logo logo--small">
                            <img src={logo} alt="Happier"/>
                        </div>
                        <p className="footer__text">Copyright 2024 - Todos los derechos reservados - Potenciado
                            por <strong>Fanbag</strong></p>
                    </div>
                </div>


            </section>
        </section>
    );
}

export default PasswordReset;