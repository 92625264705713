import TopBanner from "../Layout/TopBanner";
import { HeaderMenu } from "./HeaderMenu";
import { Search } from "./Search";
import { Notifications } from "./Notifications";
import { Profile } from "./Profile";
import UserContext from "../../context/UserContext";
import { useContext, useEffect, useState } from "react";

const Header = ({ headerLogo = null }) => {

  const { user, setUser } = useContext(UserContext);

  const [logoType, setLogoType] = useState("horizontal");

  useEffect(()=>{setLogoType(localStorage.getItem('logo_type'))},[])
  
  return (
    <div style={{ display: 'inline' }}>
      <TopBanner />
      {
        user.demo_mode == 1 && 
        <div className="demo-mode">
          <b className="demo-mode-b">Atención! </b><p>Happier se encuentra en modo demostración</p>
        </div>
      }

      <div className={`header ${(logoType == "cuadrado") ? 'header--big' : ''}`}>
        <div className="header__inner">
          <div className="header__content">

            <HeaderMenu headerLogo={headerLogo} />

            <div className="header__content__featured">

              <nav className="nav-icon">
                <Search />
                {/* TODO: descomentar. No va en primer MVP */}
                {/* <Notifications /> */}
              </nav>

              <Profile />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
