const useCloseMobileMenu = () => {
    let elements = [
        ...document.getElementsByClassName('js-menu-mobile'),
        ...document.getElementsByClassName('js-menu-mobile-overlay')
    ];

    for (const el of elements) {
        el.classList.remove('--active');
    }
}

export default useCloseMobileMenu;