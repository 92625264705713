import { useState } from "react";
import OccasionsContext from "./OccasionsContext";

const OccasionsState = (props) => {
  const [occasions, setOccasions] = useState({});

  return (
    <OccasionsContext.Provider value={{occasions, setOccasions}}>
      {props.children}
    </OccasionsContext.Provider>
  );
};
export default OccasionsState;
