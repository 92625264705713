import headerImg from '../../assets/images/img-header-panel.jpg'
import { Outlet, NavLink } from 'react-router-dom'
import { useContext } from 'react';
import UserContext from '../../context/UserContext';
import { formatPoints } from '../../utils';
import AvatarImage from '../UI/AvatarImage';

const ProfileLayout = () => {
  const { user } = useContext(UserContext);

  return (
        <>
            <section className="header-page header-page--panel" style={{ backgroundImage: `url(${headerImg})` }}>
                <div className="header-page__holder">
                    <div className="header-page__item">
                        <AvatarImage name={user.name} big={true}/>
                        <div className="header-page__item__panel">
                            <h3 className="header-page__title">{user.full_name}</h3>
                            <div className="header-page__info">
                                <p className="header-page__info__email">{user.email}</p>
                                <p className="points points--panel">{formatPoints(user.points)}<span>puntos</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <nav className="nav-panel">
                <div className="nav-panel__nav">
                    <NavLink to="/perfil" className={(navData) => (navData.isActive ? 'nav-panel__item --active' : 'nav-panel__item')}>
                        <i className="ri-user-line"></i><span>Mi</span> Perfil
                    </NavLink>
                    <NavLink to="/mis-puntos" className={(navData) => (navData.isActive ? 'nav-panel__item --active' : 'nav-panel__item')}>
                        <i className="ri-coin-line"></i><span>Mis</span> Puntos
                    </NavLink>
                    <NavLink to="/mis-consumos" className={(navData) => (navData.isActive ? 'nav-panel__item --active' : 'nav-panel__item')}>
                        <i className="ri-file-list-3-line"></i><span>Mis</span> Consumos
                    </NavLink>
                    <NavLink to="/favoritos" className={(navData) => (navData.isActive ? 'nav-panel__item --active' : 'nav-panel__item')}>
                        <i className="ri-heart-line"></i><span>Mis</span> Favoritos
                    </NavLink>
                </div>
            </nav>

            <Outlet />
        </>
    )
}

export default ProfileLayout