import { Link } from 'react-router-dom'

// type: atention (default), error o success
// link (opcional): to (requerido), state (opcional)
const Alert = ({ text, type, link }) => {
    let to, msg, state;

    if (link) {
        to = link?.to;
        msg = link?.msg;
        state = link?.state;
    }

    return (
        <div className={`alert alert--${type || 'atention'} --mb30`}>
            <span className="alert__text">
                {type === "error" && <i className="ri-close-circle-line"></i>}
                {type === "success" && <i className="ri-checkbox-circle-line"></i>}
                {type === "atention" && <i className="ri-alert-line"></i>}
                <span dangerouslySetInnerHTML={{ __html: text ?? '' }}></span>
            </span>

            {to &&
                <Link to={to} state={state} className="button button--link-reverse button--small button--link-primary">
                    {msg}<i className="ri-arrow-right-s-line"></i>
                </Link>
            }
        </div>
    )
}

export default Alert