import { useEffect, useState } from 'react'
import useChangeHandler from '../../hooks/useChangeHandler';
import useSetActive from '../../hooks/useSetActive';
import { axiosInstance } from '../../services';
import Alert from '../UI/Alert';
import Select from '../UI/Select';

const ConfirmationQuestionsModal = ({ onCloseModal, questionData }) => {
    const [activeModalClass, toggleActiveModalClass] = useSetActive(); // Lo hago por active para poder mostrar la animación

    const [loading, setLoading] = useState(false)


    useEffect(() => {
        toggleActiveModalClass(true);
    }, [])

    const closeModal = () => {
        toggleActiveModalClass(false);
        setTimeout(() => {
            onCloseModal();
        }, 400)
    }

    return (
        <>
            <div className={`modal js-valoracion-experiencia ${activeModalClass}`}>
                <div className={`modal__inner ${activeModalClass}`}>
                    <i className="button button--icon button--icon-close js-btn-valoracion-experiencia ri-close-line" onClick={closeModal}></i>
                    <h2 className="section__title --mb12">Consulta generada con éxito</h2>
                    <p className="section__text --center --mb40">Numero de referencia: {questionData.id}</p>
                    <button type="" onClick={() => { closeModal() }} className="button button--primary button--medium">Cerrar</button>
                </div>
            </div>
            <div className={`modal__overlay js-valoracion-experiencia-overlay ${activeModalClass}`}></div>
        </>
    )
}

export default ConfirmationQuestionsModal