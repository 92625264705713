import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useCloseMobileMenu from '../../hooks/useCloseMobileMenu';

// Scrolleo arriba de todo a cada cambio de pagina y cierro menu mobile
const ScrollToTop = ({ children }) => {
  useCloseMobileMenu(); // cierro menu mobile

  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

export default ScrollToTop;