import { useEffect } from "react";
import { useLocation } from "react-router";

const useScrollToId = () => {
  const { state } = useLocation();

  useEffect(() => {
    if (state?.id) {
      const el = document.getElementById(state.id)
      if (el) {
        setTimeout(() => {
          var scrollDiv = document.getElementById(state.id).offsetTop;
          window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
        }, 10);
      }
    }
  }, [state])
};

export default useScrollToId;