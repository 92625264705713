import logo from "../../assets/images/logo-happier-header.png";
import { NavLink } from "react-router-dom";
import useSetActive from "../../hooks/useSetActive";
import { useContext, useState, useEffect } from "react";
import CategoriesContext from '../../context/CategoriesContext';
import OccasionsContext from "../../context/OccasionsContext";
import UserContext from "../../context/UserContext";

export const HeaderMenu = ({ headerLogo = null }) => {
    const [categoriesActiveClass, toggleCategoriesMenu] = useSetActive();
    const [occasionsActiveClass, toggleOccasionsMenu] = useSetActive();

    const { categories } = useContext(CategoriesContext);
    const { occasions } = useContext(OccasionsContext);
 
    const { user, setUser } = useContext(UserContext);

    const [benefits_enabled, setBenefits_enabled] = useState(user.benefits_enabled);

    const [logoType, setLogoType] = useState("horizontal");

    const showMobileMenu = () => {
        let elements = [
            ...document.getElementsByClassName('js-menu-mobile'),
            ...document.getElementsByClassName('js-menu-mobile-overlay')
        ];

        for (const el of elements) {
            el.classList.add('--active');
        }
    }

      useEffect(()=>{setLogoType(localStorage.getItem('logo_type'))},[])

    return (
        <div className="header__content__primary">
            <nav className="nav-icon" onClick={showMobileMenu}>
                <div className="nav-icon__item js-btn-menu-mobile"><i className="nav-icon__item__icon ri-menu-line"></i></div>
            </nav>
            <div className={`logo ${(logoType == "cuadrado") ? 'logo--big' : ''}`}>
                <NavLink to="/home"><img src={headerLogo ?? logo} alt="Logotipo" /></NavLink>
            </div>
            <nav className="nav-menu">
                <div className="nav-menu__dropdown js-btn-navmenu" onMouseEnter={() => toggleCategoriesMenu(true)} onMouseLeave={() => toggleCategoriesMenu(false)} onClick={toggleCategoriesMenu}>
                    <div className="nav-menu__link nav-menu__link--primary" >Experiencias<i className={`nav-menu__link__arrow ri-arrow-down-s-line ${categoriesActiveClass}`}></i></div>
                    <div className={`menu-desktop js-navmenu ${categoriesActiveClass}`}>
                        <div className={`menu-desktop__content js-navmenu-content ${categoriesActiveClass}`}>
                            <ul className="menu-desktop__nav">
                                {categories.map((category) =>
                                    <li key={category.value} className="menu-desktop__nav__item">
                                        <NavLink to={`/experiencias${category.url}`} className="nav-menu__link nav-menu__link--submenu">
                                            {category.name}
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="nav-menu__dropdown js-btn-navmenu" onMouseEnter={() => toggleOccasionsMenu(true)} onMouseLeave={() => toggleOccasionsMenu(false)} onClick={toggleOccasionsMenu}>
                    <div className="nav-menu__link nav-menu__link--primary" >Ocasiones<i className={`nav-menu__link__arrow ri-arrow-down-s-line ${occasionsActiveClass}`}></i></div>
                    <div className={`menu-desktop js-navmenu ${occasionsActiveClass}`}>
                        <div className={`menu-desktop__content js-navmenu-content ${occasionsActiveClass}`}>
                            <ul className="menu-desktop__nav">
                                {occasions.map((occasion) =>
                                    <li key={occasion.value} className="menu-desktop__nav__item">
                                        <NavLink to={occasion.code ? `/experiencias/${occasion.code}` : `/experiencias`} className="nav-menu__link nav-menu__link--submenu">
                                            {occasion.name}
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>

                {/* <NavLink to="/experiencias" className="nav-menu__link nav-menu__link--primary">Ocasiones</NavLink> */}
                <NavLink to="/experiencias/destacados" className="nav-menu__link nav-menu__link--primary">Destacados</NavLink>
                <NavLink to="/giftcards" className="nav-menu__link nav-menu__link--primary">Gift Card</NavLink>

                {
                    benefits_enabled === 1 && (
                        <NavLink to="/benefits" className="nav-menu__link nav-menu__link--primary">
                            Beneficios
                        </NavLink>
                    )
                }
            </nav>
        </div>
    );
}

