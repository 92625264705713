// BOOTSTRAP
import React from "react";
import ReactDOM from "react-dom";
import "./assets/base.css";
import UserState from "./context/UserState";
import CategoriesState from "./context/CategoriesState";
import OccasionsState from "./context/OccasionsState";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";

import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <OccasionsState>
      <CategoriesState>
        <UserState>
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </BrowserRouter>
        </UserState>
      </CategoriesState>
    </OccasionsState>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
