import useSetActive from "../../hooks/useSetActive";
import { Link, useNavigate } from "react-router-dom";
import useCloseMobileMenu from "../../hooks/useCloseMobileMenu";
import { useContext } from "react";
import UserContext from "../../context/UserContext";
import { formatPoints } from "../../utils";
import AvatarImage from "./AvatarImage";
import CategoriesContext from "../../context/CategoriesContext";
import OccasionsContext from "../../context/OccasionsContext";
import { axiosInstance } from "../../services";

const MenuMobile = () => {
    const navigate = useNavigate()

    const [activeCategoriesMenuClass, toggleCategoriesMenuClass] = useSetActive();
    const [activeOccasionsMenuClass, toggleOccasionsMenuClass] = useSetActive();
    const [activePerfilMenuClass, togglePerfilMenuClass] = useSetActive();

    const { user, setUser } = useContext(UserContext);
    const { categories } = useContext(CategoriesContext);
    const { occasions } = useContext(OccasionsContext);

    const logoutHandler = async () => {
        await axiosInstance.post("/logout")
        setUser({})
        localStorage.removeItem('logged');
        navigate('/login')
    }

    return (
        <div className="menu-mobile js-menu-mobile">
            <i className="button button--icon button--icon-close js-menu-mobile-close ri-close-line" onClick={useCloseMobileMenu}></i>
            <div className="menu-mobile__inner">
                <div className="menu-mobile__top">
                    <div className="nav-login js-btn-mobilemenu-logged" onClick={togglePerfilMenuClass}>
                        <AvatarImage name={user.name} big={true} />
                        <div className="nav-login__content">
                            <p className="nav-login__title">
                                {user.name}
                                <i className="nav-login__content__arrow ri-arrow-down-s-line"></i>
                            </p>
                            <p className="points points--panel">
                                {formatPoints(user.points)} <span>puntos</span>
                            </p>
                        </div>
                    </div>

                    <ul className={`menu-mobile__nav menu-mobile__nav--logged js-mobilemenu-logged ${activePerfilMenuClass}`}>
                        <li className="menu-mobile__nav__item">
                            <Link to="/perfil" className="nav-menu__link">
                                <i className="nav-menu__link__icon ri-user-line"></i>Mi perfil
                            </Link>
                        </li>
                        <li className="menu-mobile__nav__item">
                            <Link to="/mis-puntos" className="nav-menu__link">
                                <i className="nav-menu__link__icon ri-coin-line"></i>Mis puntos
                            </Link>
                        </li>
                        <li className="menu-mobile__nav__item">
                            <Link to="/mis-consumos" className="nav-menu__link">
                                <i className="nav-menu__link__icon ri-file-list-3-line"></i>Mis Consumos
                            </Link>
                        </li>
                        <li className="menu-mobile__nav__item">
                            <Link to="/favoritos" className="nav-menu__link">
                                <i className="nav-menu__link__icon ri-heart-line"></i>Mis Favoritos
                            </Link>
                        </li>
                    </ul>
                </div>

                <ul className="menu-mobile__nav">
                    <li className="menu-mobile__nav__item">
                        <div className="nav-menu__link js-btn-menu-mobile-dropdown" onClick={toggleCategoriesMenuClass}>
                            Categorias
                            <i className={`nav-menu__link__arrow ri-arrow-down-s-line ${activeCategoriesMenuClass}`}></i>
                        </div>
                        <ul className={`menu-mobile__nav__dropdown js-menu-mobile-dropdown ${activeCategoriesMenuClass}`}>
                            {categories.map(category =>
                                <li key={category.value} className="menu-mobile__nav__subitem">
                                    <Link
                                        to={`/experiencias${category.url}`}
                                        className="nav-menu__link nav-menu__link--submenu"
                                    >
                                        {category.name}
                                    </Link>
                                </li>)}
                        </ul>
                    </li>

                    <li className="menu-mobile__nav__item">
                        <div className="nav-menu__link js-btn-menu-mobile-dropdown" onClick={toggleOccasionsMenuClass}>
                            Ocasiones
                            <i className={`nav-menu__link__arrow ri-arrow-down-s-line ${activeOccasionsMenuClass}`}></i>
                        </div>
                        <ul className={`menu-mobile__nav__dropdown js-menu-mobile-dropdown ${activeOccasionsMenuClass}`}>
                            {occasions.map(occasion =>
                                <li key={occasion.value} className="menu-mobile__nav__subitem">
                                    <Link
                                        to={`/experiencias`}
                                        state={{ occasionId: occasion.value }}
                                        className="nav-menu__link nav-menu__link--submenu"
                                    >
                                        {occasion.name}
                                    </Link>
                                </li>)}
                        </ul>
                    </li>
                    <li className="menu-mobile__nav__item">
                        <Link to="/experiencias/destacados" className="nav-menu__link">
                            Destacados
                        </Link>
                    </li>
                    <li className="menu-mobile__nav__item">
                        <Link to="/giftcards" className="nav-menu__link">
                            Gift Card
                        </Link>
                    </li>
                </ul>
            </div>
            <ul className="menu-mobile__bottom">
                <li className="menu-mobile__nav__item">
                    <span onClick={logoutHandler} className="nav-menu__link">
                        <i className="nav-menu__link__icon ri-logout-circle-line"></i>
                        Cerrar sesión
                    </span>
                </li>
            </ul>
        </div>
    )
}

export default MenuMobile