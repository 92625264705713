import { useNavigate } from 'react-router-dom'
import Alert from "../../components/UI/Alert";
import {useState} from "react";
import useValidator from "../../hooks/useValidator";
import useChangeHandler from "../../hooks/useChangeHandler";
import {generatePassword} from "../../utils/password";
import {axiosInstance} from "../../services";


const PasswordResetForm = ({ hash, user_id, date, corporateUrl }) => {

    const initialState = {
        'user_id': user_id,
        'hash' : hash,
        'date' : date,
        'password': '',
        'newPassword': '',
        'confirmNewPassword': '',
        'company_url': corporateUrl
    }

    const navigate = useNavigate();

    /* Cambio de contraseña */
    const [formData, setFormData] = useState(initialState);
    const [validator, showValidationMessage] = useValidator()
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertText, setErrorAlertText] = useState("");
    const [validationErrors, setValidationErrors] = useState({});
    const [generatedPassword, setGeneratedPassword] = useState("");
    /***/

    const passwordChangeHandler = useChangeHandler(setFormData)

    const passToogleConfirm = (element, id) => {

        var input = document.getElementById(id);
        var icon = document.querySelectorAll('.form-item__input__icon_right');

        if (input.style[0] == "-webkit-text-security") {
            input.style = "";
            icon[element].className = 'form-item__input__icon_right ri-eye-line';

        } else {
            input.style = "-webkit-text-security:disc";
            icon[element].className = 'form-item__input__icon_right ri-eye-off-line';

        }

    }

    const generateStrongPassword = () => {
        setGeneratedPassword(generatePassword({}))

    }

    const copyPassword = () =>{
        navigator.clipboard.writeText(generatedPassword);
    }

    const saveData = async (e) => {
        e.preventDefault()

        setShowSuccessAlert(false)
        setErrorAlertText(null)
        setShowErrorAlert(false)
        setGeneratedPassword("")

        if (validator.allValid()) {
            try {
                const response = await axiosInstance.post(`/first_login_password_change`, { ...formData });
                if (response?.status === 200) {
                    setShowSuccessAlert(true)
                    localStorage.removeItem('must_change_password')

                }
            } catch (error) {
                console.log(error)

                let message = error?.response?.data?.message
                const statusCode = error?.response?.status
                const errors = error?.response?.data?.errors

                if(errors){
                    message="<br>"
                    for (const property in errors) {
                        message = message + errors[property] +"<br>"
                    }
                }

                if (statusCode === 422 || statusCode === 401) {

                    setErrorAlertText('Ocurrió un error al intentar actualizar el perfil. ' + message)
                } else {
                    setErrorAlertText('Ocurrió un error al intentar actualizar el perfil.')
                }

                setShowErrorAlert(true)
            }
            setValidationErrors({})
        } else {
            setValidationErrors(validator.getErrorMessages())
            showValidationMessage(true)
        }
    }

    return (
            <div id="js-modal-change-password" className="modal__item">
                <h2 className="section__title --mb12">Cambiar contraseña</h2>

                {showSuccessAlert && <Alert type="success" text="Se ha cambiado la contraseña correctamente."/>}
                {showErrorAlert && <Alert type="error" text={errorAlertText}/>}

                {!showSuccessAlert && <form action="" method="post" autoComplete="off" className="modal__form modal__form--login">
                    <p className="section__text --mb35">
                        Por seguridad, en tu primer acceso, necesitamos que cambies tu contraseña por una nueva.
                    </p>
                    <div className="form-item">
                        <label htmlFor="old_password" className="form-item__label">Contraseña actual</label>
                        <div className="form-item__input">
                            <i className="form-item__input__icon_right ri-eye-off-line" onClick={() => {
                                passToogleConfirm(0, "old_password")
                            }}></i>
                            <input type="text" id="old_password" name="password" autoComplete="new-password"
                                   style={{WebkitTextSecurity: 'disc'}}
                                   className={`input input--border ${validationErrors.old_password && 'input--error'}`}
                                   onChange={passwordChangeHandler} required></input>
                            {!validationErrors.name && <span className="form-item__input__focus"></span>}
                        </div>
                        {validator.message('old_password', formData.password, 'required')}
                    </div>

                    <div className="form-item">
                        <div className="form-item --footer --mb0" style={{alignItems: 'flex-start'}}>
                            <label htmlFor="new_password" className="form-item__label">Nueva contraseña</label>
                            <a className="button button--link button--medium button--link-color button--no-height"
                               onClick={() => {
                                   generateStrongPassword()
                               }}>
                                <i className="ri-key-2-fill"></i>Generar contraseña
                            </a>
                        </div>
                        <div className="form-item__input">
                            <i className="form-item__input__icon_right ri-eye-off-line" onClick={() => {
                                passToogleConfirm(1, "new_password")
                            }}></i>
                            <input type="text" id="new_password" name="newPassword" autoComplete="new-password"
                                   style={{WebkitTextSecurity: 'disc'}}
                                   className={`input input--border ${validationErrors.new_password && 'input--error'}`}
                                   onChange={passwordChangeHandler} required/>
                            {!validationErrors.last_name && <span className="form-item__input__focus"></span>}
                        </div>
                        {validator.message('new_password', formData.newPassword, 'required')}
                        <p className="section__text section__text--small --mt9 --c-grey">La contraseña debe tener un
                            mínimo
                            de 12 caracteres y contener números, letras mayúsculas, letras minúsculas y simbolos.</p>
                    </div>

                    <div className="form-item">
                        <label htmlFor="repeat_new_password" className="form-item__label">Repetir nueva
                            contraseña</label>
                        <div className="form-item__input">
                            <input type="text" id="repeat_new_password" name="confirmNewPassword"
                                   autoComplete="new-password" style={{WebkitTextSecurity: 'disc'}}
                                   className={`input input--border ${validationErrors.repeat_new_password && 'input--error'}`}
                                   onChange={passwordChangeHandler} required/>
                            <i className="form-item__input__icon_right ri-eye-off-line" onClick={() => {
                                passToogleConfirm(2, "repeat_new_password")
                            }}></i>
                            {!validationErrors.alias && <span className="form-item__input__focus"></span>}
                        </div>
                        {validator.message('repeat_new_password', formData.confirmNewPassword, 'required')}
                    </div>


                    {(generatedPassword != "") &&
                        <>
                            <div className="alert alert--atention --mb25">
                                                        <span className="alert__text">
                                                        Esta es tu nueva contraseña generada: <strong>{generatedPassword}</strong><br/>
                                                        Copiala en un lugar seguro antes de cambiarla.<br/>
                                                        <a onClick={copyPassword}
                                                           className="button button--link button--medium button--bold button--link-color button--no-height --mt12">
                                                            <i className="ri-file-copy-line"></i>Copiar contraseña
                                                        </a>
                                                        </span>
                            </div>
                        </>
                    }

                    {
                        <div className="form-item --footer --center --mt25">
                            <button type="submit"
                                    className="button button--primary button--medium js-btn-datos-personales"
                                    onClick={saveData} data-sitekey="" data-callback="">
                                <span>Cambiar contraseña</span>
                            </button>
                        </div>
                    }
                </form>}

                {showSuccessAlert &&
                    <a className="button button--primary button--medium js-btn-datos-personales" onClick={()=>{navigate('/login')}} data-sitekey="" data-callback="">
                        <span>Continuar al login</span>
                    </a>
                }
            </div>
    );
}

export default PasswordResetForm;