import { useEffect, useState, useRef } from 'react'
import useChangeHandler from '../../hooks/useChangeHandler';
import useSetActive from '../../hooks/useSetActive';
import { axiosInstance } from '../../services';
import Alert from '../UI/Alert';
import Select from '../UI/Select';
import ConfirmationQuestionsModal from './ConfirmationQuestionsModal';
import Loader from '../UI/Loader'

const reasons = [
    { name: 'Consulta', value: 'consulta' },
    { name: 'Reclamo', value: 'reclamo' },
    { name: 'Administrativo', value: 'administrativo' },
    { name: 'Otro', value: 'otro' },
]

const initialState = {
    msg: null,
    reason: 'Consulta',
}

const QuestionsModal = ({ onCloseModal }) => {
    const [activeModalClass, toggleActiveModalClass] = useSetActive(); // Lo hago por active para poder mostrar la animación

    const [formData, setFormData] = useState(initialState)
    const [showError, setShowError] = useState(false)
    const [loading, setLoading] = useState(false)

    const changeHandler = useChangeHandler(setFormData)

    const [showConfirmationCuestionModal, setConfirmationCuestionModal] = useState(false)

    const [sendSuccess, setSendSuccess] = useState(false);
    const [questionDataResponse, setQuestionDataResponse] = useState(false);
    const questionForm = useRef(0);

    useEffect(() => {
        toggleActiveModalClass(true);
    }, [])

    function closeModal(questionData = false) {

        toggleActiveModalClass(false);
        setTimeout(() => {
            onCloseModal(questionData);
        }, 400)
    }

    const submitData = async (e) => {
        e.preventDefault()
        setShowError(false)
        setLoading(true)
        questionForm.current.reset();

        try {
            await new Promise(resolve => setTimeout(resolve, 2000));
            const response = await axiosInstance.post(`/contact`, { ...formData });
            if (response?.status === 201) {
                //  closeModal(response.data.questionData)
                setQuestionDataResponse(response.data.questionData);

                setLoading(false)
                setSendSuccess(true);
            }
        } catch (error) {
            setShowError(true)
        } finally {
        }
    }

    return (
        <>
            <div className={`modal js-valoracion-experiencia ${activeModalClass}`}>
                <div className={`modal__inner ${activeModalClass}`}>
                    <i className="button button--icon button--icon-close js-btn-valoracion-experiencia ri-close-line" onClick={closeModal}></i>
                    <h2 className="section__title --mb12">Consultas</h2>
                    <p className="section__text --center --mb40">Por favor completa el siguiente formulario para enviarnos una consulta</p>

                    {showError && <Alert text={"Ocurrió un error al intentar enviar el formulario"} type="error" />}

                    <form ref={questionForm} action="" method="post" className="modal__form modal__form--grid-2" onSubmit={submitData}>

                        <div className="form-item">
                            <div className="form-item__input form-item__input--select">
                                <label className="form-item__label">Motivo*</label>
                                <Select
                                    items={reasons}
                                    onChange={(value) => setFormData((prev) => { return { ...prev, reason: value } })}
                                    defaultValue={reasons[0].value} />
                            </div>
                        </div>

                        <div className="form-item --fullwidth">
                            <label htmlFor="msg" className="form-item__label">Mensaje</label>
                            <div className="form-item__input --textarea">
                                <textarea name="msg" id="msg" className="input input--textarea" rows="10" cols="50" placeholder="Escriba su mensaje aquí" onChange={changeHandler} required></textarea>
                                <span className="form-item__input__focus"></span>
                            </div>
                        </div>

                        <div className="form-item --fullwidth">
                            <p className="section__text">*Todos los campos son obligatorios.</p>
                        </div>


                        <div className="form-item --footer --center --mt30">
                            {
                                loading ? <button type="submit" className="button button--primary button--medium button--loading custom-loading-btn-contact"><span className="">Enviar consulta</span><div className="button--loading__animation"></div></button>

                                    :

                                    <button type="submit" className="button button--primary button--medium custom-loading-btn-contact"><span className="">Enviar consulta</span></button>
                            }
                        </div>
                        <div className="form-item --fullwidth">
                            {sendSuccess && <Alert text={"El formulario se envió correctamente.<br> Número de referencia es <strong>" + questionDataResponse.id +"</strong>"} type="success"></Alert>}
                        </div>
                    </form>
                </div >
            </div >
            <div className={`modal__overlay js-valoracion-experiencia-overlay ${activeModalClass}`}></div>
            {showConfirmationCuestionModal && <ConfirmationQuestionsModal onCloseModal={() => setConfirmationCuestionModal(false)} />}

        </>
    )
}

export default QuestionsModal