import { useState } from "react";
import CategoriesContext from "./CategoriesContext";

const CategoriesState = (props) => {
  const [categories, setCategories] = useState({});

  return (
    <CategoriesContext.Provider value={{categories, setCategories}}>
      {props.children}
    </CategoriesContext.Provider>
  );
};
export default CategoriesState;
