import { useCallback, useState } from "react";
import { useNavigate } from "react-router";

const useAsyncError = () => {
    const navigate = useNavigate();
    const [_, setError] = useState();
    return useCallback(
      (e, status = null) => {
        if(status === 404) return navigate('/not-found')
        if(e.message === 'Cancel: canceled') return // Si es un controller.abort no tiro error
        setError(() => {
          throw e;
        });
      },
      [setError, navigate],
    );
  };

export default useAsyncError;