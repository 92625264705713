import { useState } from "react";

const useSetActive = () => {
    const [activeClass, setActiveClass] = useState('');

    const toggleActiveClass = (force = true) => {
        setActiveClass((prev) => {
            if (prev === "" && force) return "--active";
            return "";
        });
    };

    return [activeClass, toggleActiveClass];
};

export default useSetActive;