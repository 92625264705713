
import { Link, useNavigate, useParams, Navigate } from 'react-router-dom'
import Alert from '../../components/UI/Alert';
import { useEffect, useState } from 'react';
import useChangeHandler from '../../hooks/useChangeHandler';
import { axiosInstance } from '../../services';
import logo from "../../assets/images/logo-happier-header.png";


const PasswordRecovery = ({callback}) => {
    
    const initialState = {
        email: null,
        corporateUrl: localStorage.getItem('company'),
    }
    
    const navigate = useNavigate();

	const { corporateUrl } = useParams();

    const [showErrorNotFound, setShowErrorNotFound] = useState(false)
    const [showErrorUnknown, setShowErrorUnknown] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [formData, setFormData] = useState(initialState)
    
    const changeHandler = useChangeHandler(setFormData)
    
	const [foundCorporate, setFoundCorporate] = useState(true)


    const getValidatedEmail = async () => {

        setShowErrorNotFound(false)
        setShowErrorUnknown(false)
        setShowSuccess(false)
        
        const response = await axiosInstance.get("/validate_email_recovery", { params: { data:formData } })
          .then(response => {
            if (response?.data.status === "success") {
                setShowSuccess(true)
            }else {
                setShowErrorNotFound(true)
            }
          }).catch(e => {
                setShowErrorUnknown(true)
          })
    }



	if (!foundCorporate) return <Navigate to="/not-found" replace />;

    return (
        <>
            <i class="message-success__icon --mb12 ri-key-2-fill"></i>
            <h2 className="section__title --mb12">Restablecer contraseña</h2>
            <p className="section__text --mb40">Por favor ingresa tu email debajo para recibir un link de recuperación de contraseña</p>

            <form action="" method="post" className="modal__form modal__form--login">
                {showSuccess && <Alert text={`Recibirás un correo electrónico para generar una nueva contraseña.`}  type="success" />}
                {showErrorNotFound && <Alert text={"Email invalido."} type="error" />}
                {showErrorUnknown && <Alert text={"Error desconocido."} type="error" />}
                
                <div className="form-item --mb25">
                    <label className="form-item__label">Usuario / Email:</label>
                    <div className="form-item__input">
                        <input type="email" id="email" className="input input--border" required onChange={(event) => setFormData((prev) => ({ ...prev, email: event.target.value }))} />
                        <span htmlFor="email" className="form-item__input__focus"></span>

                    </div>
                </div>
                
                <div className="form-item --center">
                    <button type="button" onClick={getValidatedEmail} className="button button--primary button--big">Enviar</button>
                </div>

                <div className="form-item --center">
                    <button type="button" onClick={callback} className="button button--link button--link-primary button--medium button--no-height">
                        <i class="ri-arrow-left-line"></i>Volver
                    </button>
                </div>
            </form>
        </>
    );
}

export default PasswordRecovery;