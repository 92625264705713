import React, { useEffect, useState } from 'react'
import useSetActive from '../../hooks/useSetActive';
import { axiosInstance } from '../../services';
import TermsConditions from '../../pages/TermsConditions';
import InputAlert from './InputAlert';



const TermsConditionsModal = ({ onCloseModal }) => {
    const [activeModalClass, toggleActiveModalClass] = useSetActive(); 
    const [termsValidated, setTermsValidated] = useState(false);
    let check = document.getElementById("checkbox");
    let textError = "";

    useEffect(() => {
        setTimeout(() => {
            toggleActiveModalClass(true);
        }, 800)
        
    }, [])


    const closeModal = () => {
        toggleActiveModalClass(false);
        setTimeout(() => {
            onCloseModal();
        }, 400)
    }

    const acceptForm = async () => {

        if(check.checked){

            const response = await axiosInstance.post("/accept_terms", {accept:true})
              .then(response => {
                if (response?.status === 200) {
                    setTermsValidated(true);
                    closeModal();
                }
              })
              .catch(e => {
                console.log(e);
              })
        } else {
            setTermsValidated(false);
        }
      }  
      
      const clickCheck = () => {
        if(check.checked){
            setTermsValidated(true);
            textError = "";
        } else {
            setTermsValidated(false);
            textError = "Por favor acepta los Terminos y Condiciones";
            
        }
      } 
      

    return (
        <>
            <div className={`modal modal--scroll ${activeModalClass}`}>
                <div className={`modal__inner modal__inner--big --fullheight ${activeModalClass}`}>
                    
                    <div className="modal__item">
                        <TermsConditions/>
                    </div>

                    <hr className="section__separate --mt20" />
                    <div className="form-item --footer --mt20">
                        <div>
                        <label onClick={clickCheck} className="checkbox">He leido y acepto los Terminos y Condiciones<input id="checkbox" type="checkbox" value="checked"/><span className="checkbox__checkmark"></span></label>
                        {!termsValidated && <InputAlert text={textError} />}
                        </div>
                        <button onClick={acceptForm} className={termsValidated ? 'button button--primary button--medium' : 'button button--grey button--medium'}>Aceptar</button>
                    </div>         
                </div>
            </div>
            <div className={`modal__overlay js-link-booking-overlay ${activeModalClass}`}></div>
        </>
    )
}

export default TermsConditionsModal