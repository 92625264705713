import { useState, useContext, useEffect } from "react";
import { useNavigate, Link, useParams, Navigate, useSearchParams } from "react-router-dom";
import logo from "../../assets/images/logo-happier-header.png";
import Alert from "../../components/UI/Alert";
import Loader from "../../components/UI/Loader";
import UserContext from "../../context/UserContext";
import useChangeHandler from "../../hooks/useChangeHandler";
import { axiosInstance } from '../../services';

const LoginUnilever = () => {
	const navigate = useNavigate();
	const { corporateUrl } = useParams();

	const { setUser: setGlobalUser } = useContext(UserContext);

	const [loading, setLoading] = useState(true)
	const [foundCorporate, setFoundCorporate] = useState(true)
	const [showError, setShowError] = useState(false)

	const [companyLogo, setCompanyLogo] = useState(null)
	const [loginType, setLoginType] = useState(null)

	const [user, setUser] = useState({
		password: "",
		email: "",
	});

	//SSO login variables
	const [callbackURL, setCallbackURL] = useState(null)
	const [showErrorSSO, setShowErrorSSO] = useState(false)
	const [searchParams] = useSearchParams();
	const code = searchParams.get('code');
	const state = searchParams.get('state');

	const changeHandler = useChangeHandler(setUser)

	useEffect(() =>{

		if(code !== undefined && state !== undefined){
			
			const company_id = localStorage.getItem('company_id')

			const sendCallback = async () => {
			const response = await axiosInstance.get('/unilever/auth0/callback', { 
				params: { 
					code: code,
					state: state, 
					url: corporateUrl,
					corporate_id: company_id
				} 
			}).then(response => {
				setLoading(false);
				if (response?.status === 200) {
					localStorage.removeItem('company_id');
					navigate('/home');
				}else{
					localStorage.removeItem('company_id');
					navigate('/login');
				}
			  }).catch(e => {
				  console.log(e);
					if(e.response?.status === 404){
						navigate('/login', {state:{notFound:true}});
					}else{
						navigate('/login');
					}
				  })
			  }
			  sendCallback();
		}
	},[])

	// Si la url no matchea con una empresa, devuelvo 404
	if (!foundCorporate) return <Navigate to="/not-found" replace />;

	return (
		loading ? <Loader />
			:
			<section style={{ backgroundColor: "#f5f5f5" }}>
				<section className="section section--login">
					
				</section>
			</section>
	);
}
export default LoginUnilever;