import React from 'react'
import { Link, Outlet } from 'react-router-dom'

const FaqLayout = () => {
    return (
        <>
            <section className="header-page header-page--news">
                <div className="header-page__holder">
                    <div className="header-page__item">
                        <div className="header-page__title">Términos y Condiciones</div>
                    </div>

                    <div className="header-page__item">
                        <ul className="breadcrumb">
                            <li>
                                <Link to="/">Inicio</Link>
                            </li>
                            <li>Preguntas frecuentes</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="section__holder">
                    <div className="section__inner section__inner--grid-2 --filters">
                        <div className="section__item --faq">
                            <div className="filters-bar filters-bar--faq">
                                <ul className="filters-bar__content">
                                    <div className="filters-bar__item --desktop">
                                        <li className="menu-desktop__nav__item --title --mb6"><p className="filters-bar__title">Términos y Condiciones</p></li>
                                        <li className="menu-desktop__nav__item">
                                            <Link to="/terminos-condiciones" state={{ id: 'politicas-privacidad' }} className="nav-menu__link nav-menu__link--submenu --underline">
                                                Políticas de Privacidad
                                            </Link>
                                        </li>
                                        <li className="menu-desktop__nav__item">
                                            <Link to="/terminos-condiciones" state={{ id: 'condiciones-generales' }} className="nav-menu__link nav-menu__link--submenu --underline">
                                                Condiciones Generales
                                            </Link>
                                        </li>
                                    </div>
                                    <hr className="section__separate --mt15 --mb15" />
                                    <div className="filters-bar__item">
                                        <li className="menu-desktop__nav__item --title --mb6"><p className="filters-bar__title">Preguntas Frecuentes</p></li>
                                        <li className="menu-desktop__nav__item">
                                            <Link to="/preguntas-frecuentes" state={{ id: 'proceso-canje-reserva' }} className="nav-menu__link nav-menu__link--submenu --underline js-anchor">
                                                Proceso de Canje y Reserva
                                            </Link>
                                        </li>
                                        <li className="menu-desktop__nav__item">
                                            <Link to="/preguntas-frecuentes" state={{ id: 'sobre-puntos' }} className="nav-menu__link nav-menu__link--submenu --underline js-anchor">
                                                Sobre los puntos
                                            </Link>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <Outlet />
                    </div>
                </div>
            </section>

        </>
    )
}

export default FaqLayout