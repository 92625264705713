import { useState, useRef, useEffect } from 'react'
import useSetActive from '../../hooks/useSetActive'
import useOutsideHandler from "../../hooks/useOutsideHandler";


// Items: array con name y value
const Select = ({ label = '', items, onChange, defaultValue, innerLabel = false, sm = false, disabled = false, hasError = false, search = null }) => {
    const [activeClass, toggleActiveClass] = useSetActive();
    const [selectLabel, setSelectLabel] = useState('');
    const [innerSelectLabel, setInnerSelectLabel] = useState('');
    const [options, setOptions] = useState([...items]);
    const [q, setQ] = useState('')
    const wrapperRef = useRef(null);

    useOutsideHandler(wrapperRef, toggleActiveClass, 'Select');

    const onChangeHandler = (item) => {
        setInnerSelectLabel(item.name)
        onChange(item.value);
    }

    useEffect(() => {
        setSelectLabel(!innerLabel && label);

        if (defaultValue && items.length > 0) {
            const item = items.find(item => item.value === defaultValue);
            if (item) {
                setInnerSelectLabel(item.name)
                return
            }
        }

        if (innerLabel) {
            if (label) {
                setInnerSelectLabel(label);
            } else {
                setInnerSelectLabel(items[0].name);
                onChange(items[0].value);
            }
            setSelectLabel('');
        } else {
            setInnerSelectLabel(items[0].name);
        }
    }, [defaultValue, label, items, innerLabel])

    const onSearchHandler = (e) => {
        const val = e.target.value.toLowerCase();
        setQ(val)
        setOptions(prev => {
            if (val !== '')
                return [...items].filter(i => i.name.toLowerCase().includes(val))

            return [...items]
        })
    }

    useEffect(() => {
        setOptions([...items])
        setQ('')
    }, [items])

    return (
        <>
            <div className="select" onClick={() => { if (!disabled) return toggleActiveClass() }} ref={wrapperRef}>
                <p className="select__text">{selectLabel}</p>
                <div className={`select__button js-btn-select ${activeClass} ${hasError ? 'input--error' : ''}`}>
                    {innerSelectLabel}<i className={`select__button__arrow ri-arrow-down-s-line ${activeClass}`}></i>
                </div>
                <ul className={`select__menu ${sm ? '' : 'select__menu--right'} ${activeClass} ${options.length > 6 ? 'select__menu--scroll' : ''}`}>
                    {
                        search &&
                        <input
                            id="time"
                            type="text"
                            className="input input--border"
                            placeholder={search}
                            onClickCapture={(e) => e.stopPropagation()}
                            value={q}
                            onChange={onSearchHandler}
                        />
                    }
                    {options.map((option) =>
                        <li key={option.value} className="select__menu__link" onClick={() => onChangeHandler(option)}>{option.name}</li>
                    )}
                </ul>
            </div>
        </>
    )
}

export default Select;